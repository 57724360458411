// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-authors-rama-js": () => import("./../../../src/pages/authors/rama.js" /* webpackChunkName: "component---src-pages-authors-rama-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quotes-js": () => import("./../../../src/pages/quotes.js" /* webpackChunkName: "component---src-pages-quotes-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */),
  "component---src-templates-quote-js": () => import("./../../../src/templates/quote.js" /* webpackChunkName: "component---src-templates-quote-js" */),
  "component---src-templates-topic-js": () => import("./../../../src/templates/topic.js" /* webpackChunkName: "component---src-templates-topic-js" */)
}

